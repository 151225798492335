<!-- @format -->
<script lang="ts">
    import type { StoresKey } from "~/stores";
    import { getStoresContext } from "~/stores";
    import type { ManageAPI } from "~/manage-api/types";
    import { valueGetters } from "~/components/fragment-sets/basic_component/util";
    import OfferCard from "./OfferCard.svelte";
    import Modal from "./Modal.svelte";
    import OfferCardModal from "./OfferCardModal.svelte";

    export let request_store_key: StoresKey;
    export let fragment: Fragment;

    const stores_context = getStoresContext(request_store_key);

    const { locale, T, reverse_router, offers } = stores_context;
    const { getText } = valueGetters(stores_context);

    $: shuffledOffers = $offers.sort(() => 0.5 - Math.random());
    let id = 0;
    let focusedOffer: ManageAPI.Offer;
    $: title = $locale && getText("title", fragment);

    function focusOffer(event: CustomEvent) {
        id = event.detail;
    }
    $: if (id != 0) {
        focusedOffer = $offers.find((offer) => offer.id === id)!;
    }
</script>

{#if title && $offers && $offers.length}
    <h2>{title}</h2>
    <div class="row">
        {#each shuffledOffers.slice(0, 6) as offer}
            <OfferCard onHomePage={true} {offer} on:focusOffer={focusOffer} {request_store_key} />
        {/each}
    </div>
    {#if $offers.length > 6}
        <div class="row justify-content-center standard-margin-bottom">
            <div class="col-lg-3">
                <a
                class="see-more"
                href={$reverse_router.findPurposeRoute('offers collection')}
                >{$T("common.readMore")}</a>
            </div>
        </div>
    {:else}
        <div class="standard-margin-bottom"></div>
    {/if}
{/if}

<Modal bind:id {request_store_key}>
    {#if id}
        <OfferCardModal {request_store_key} offer={focusedOffer} />
    {/if}
</Modal>

<style lang="scss">
    a.see-more {
        font-size: 20px;
        border: 2px solid var(--brand-color-7);
        border-radius: 34.5px;
        padding: 14px 0;
        text-align: center;
        color: var(--brand-color-7);
        width: 100%;
        display: block;
        @media screen and (min-width: 992px) {
        padding: 18px 0;
        font-size: 24px;
        }
    }
    a.see-more:hover {
        background-color: var(--brand-color-7);
        color: var(--body);
        border-color: var(--body);
    }
    h2 {
        text-align: center;
        color: var(--brand-color-5);
        font-size: 24px;
        margin-bottom: 35px;
        @media screen and (min-width: 992px) {
        font-size: 52px;
        margin-bottom: 70px;
        }
    }
</style>
